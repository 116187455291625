import React, { useState } from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TabNav from '../../components/TabNav';
import WithSidebar from '../../components/WithSidebar';

import { theme } from '../../styles/theme';

const tabs = [
  {
    text: 'TEST',
    colour: 'red',
  },
  {
    text: 'SPEC',
    colour: 'green',
  },
  {
    text: 'EMERGENCY',
    colour: 'blue',
  },
];

const LabPage = () => {
  const [currentView, setCurrentView] = useState('TEST');

  return (
    <Layout navTitle="Laboratory Best Practices">
      <Seo title="Blood Components - Laboratory Best Practices" />
      <WithSidebar>
        <TabNav
          label="Practice choice"
          tabs={tabs}
          active={currentView}
          onClick={text => setCurrentView(text)}
        />

        <div>
          {currentView === 'TEST' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.red }}>
              <h1>Compatibility testing</h1>
              <h2>Neonates and infants &lt; 4 months</h2>
              <p>
                Obtain neonatal and maternal transfusion history (including any
                fetal transfusions) for all admissions..
              </p>
              <p>
                Obtain a maternal sample for initial testing where possible, in
                addition to the patient sample.
              </p>

              <h2>Red cell selection: no maternal antibodies present</h2>
              <p>
                Select appropriate group and correct neonatal specification red
                cells.
              </p>
              <p>
                Group O D-negative red cells may be issued electronically
                without serological crossmatch.
              </p>
              <p>
                If the laboratory does not universally select group O D-negative
                red cells for this age group, blood group selection should
                either be controlled by the LIMS or an IAT crossmatch should be
                performed using maternal or neonatal plasma to serologically
                confirm ABO compatibility with both mother and neonate.
              </p>

              <h2>Red cell selection: where there is maternal antibody</h2>
              <p>
                Select appropriate group red cells, compatible with maternal
                alloantibody/ies.
              </p>
              <p>
                An IAT crossmatch should be performed using the maternal plasma.
              </p>
              <p>
                If it is not possible to obtain a maternal sample it is
                acceptable to crossmatch antigen-negative units against the
                infant’s plasma.
              </p>
              <p>
                Where paedipacks are being issued from one donor unit it is only
                necessary to crossmatch the first split pack.
              </p>
              <p>
                Subsequent split packs from this multi-satellite unit can be
                automatically issued without further crossmatch until the unit
                expires or the infant is older than 4 months.
              </p>
              <p>
                If packs from a different donor are required, an IAT crossmatch
                should be performed.
              </p>

              <h2>Infants and children &ge; 4 months</h2>
              <p>
                For infants and children from 4 months of age, pre-transfusion
                testing and compatibility procedures should be performed as
                recommended for adults.
              </p>
            </article>
          )}

          {currentView === 'SPEC' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.green }}>
              <h1 className="mb-gap">Component specification</h1>
              <p>
                It is recommended that recipients under 1 year of age are
                transfused with components with neonatal/infant specification.
                These are provided by UK Blood Services.
              </p>

              <h2>Red cells</h2>
              <p>
                Allocate a set of paedipacks when first neonatal top-up
                transfusion requested (number allocated depending on local
                policy). They can be used up to 35 days after donation.
              </p>

              <h2>Platelets for neonates, infants and children</h2>
              <p>
                Small-volume apheresis packs with neonatal/infant specification
                are provided for neonates and infants. For children from 1 year
                of age, either standard apheresis or pooled platelets may be
                used.
              </p>

              <h2>
                FFP and cryoprecipitate for neonates, infants and children
              </h2>
              <p>
                Neonatal/infant specification components are provided for
                neonates and infants. Children from 1 year of age will be
                provided with the standard UK 'adult' plasma components.
              </p>

              <h2>Special situations for neonates/infants</h2>
              <p>Specific components provided by UK Blood Services for:</p>

              <ul>
                <li>
                  <p>
                    <span className="text-bold">
                      Neonatal exchange transfusion
                    </span>
                    <br />
                    use by end of Day 5, within 24 hr of irradiation.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="text-bold">
                      Neonatal/infant large volume transfusion
                    </span>
                    <br />
                    use by end of Day 5 (if irradiated, within 24 hr).
                  </p>
                </li>
                <li>
                  <p>
                    <span className="text-bold">
                      Neonatal alloimmune thrombocytopenia (NAIT)
                    </span>
                    <br />
                    when NAIT is suspected and results of diagnostic tests are
                    not available, order platelets negative for HPA-1a/5b
                    antigens until NAIT is confirmed/excluded.
                  </p>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'EMERGENCY' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.blue }}>
              <h1 className="mb-gap">
                Emergency provision for infants &lt; 1 year of age
              </h1>
              <p>
                In order to avoid delays in blood provision, if specific
                components are not available in an emergency, use local
                pre-agreed hierarchies of alternative components and
                communication pathways. Alternatives are dependent upon the
                reason for transfusion, availability of components routinely
                held in stock, timescales for delivery from the Blood Centre and
                proximity of local blood storage to the clinical area.
              </p>

              <h2 className="pt-gap">
                Hierarchy for consideration if no local policy in place:
              </h2>

              <ol className="custom-number-list">
                <li>
                  <p>
                    ABO compatibility with infant (and mother for neonates and
                    infants &lt; 4 months)
                  </p>
                </li>
                <li>
                  <p>
                    Antigen-negative for maternal antibodies for neonates and
                    infants &lt; 4 months
                  </p>
                </li>
                <li>
                  <p>Age of unit</p>
                </li>
                <li>
                  <p>Irradiation status</p>
                </li>
                <li>
                  <p>CMV negativity</p>
                </li>
                <li>
                  <p>
                    A component that satisfies the neonatal/infant specification
                    e.g. multi‑satellite packs, small volume apheresis platelets
                  </p>
                </li>
              </ol>
            </article>
          )}
        </div>
      </WithSidebar>
    </Layout>
  );
};

export default LabPage;
