import React from 'react';

import { css } from '@emotion/react';

import { theme } from '../styles/theme';

const WithSidebar = ({ children }) => {
  return (
    <div css={[theme.components.row, styles.withSidebar]}>
      <div>{children}</div>
    </div>
  );
};

const styles = {
  withSidebar: css({
    overflow: 'hidden',
    '& > *': {
      display: 'flex',
      flexFlow: 'wrap-reverse',
      marginLeft: 'calc(3rem / 2 * -1)',
      marginRight: 'calc(3rem / 2 * -1)',
      '& > *': {
        marginLeft: 'calc(3rem / 2)',
        marginRight: 'calc(3rem / 2)',
        flexBasis: '240px',
        flexGrow: 0,
      },
      '& > :last-child': {
        flexBasis: 0,
        flexGrow: 999,
        minWidth: 'calc(60% - 3rem)',
        marginBottom: theme.gap,
      },
    },
  }),
};

export default WithSidebar;
