import React from 'react';

import { css } from '@emotion/react';

import { theme } from '../styles/theme';

const TabNav = ({ tabs, label, active, onClick }) => {
  return (
    <nav aria-label={label} className="mb-gap">
      {tabs.map(tab => (
        <button
          onClick={() => onClick(tab.text)}
          key={tab.text}
          css={[styles.tab, tab.text === active && styles.active]}
          style={{
            '--theme': theme.colours[tab.colour],
            '--theme-alpha': `${theme.colours[tab.colour]}40`,
          }}>
          {tab.text}
        </button>
      ))}
    </nav>
  );
};

const styles = {
  tab: css({
    border: '2px solid var(--theme-alpha)',
    borderRadius: '5px',
    color: 'var(--theme)',
    background: 'none',
    appearance: 'none',
    width: '100%',
    marginBottom: theme.gapHalf,
    textAlign: 'left',
    position: 'relative',
    padding: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '20px',
  }),
  active: css({
    borderColor: 'var(--theme)',
    background: 'var(--theme)',
    color: theme.colours.white,
    '&:after': {
      display: 'block',
      content: "''",
      position: 'absolute',
      right: '-10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '6px 0 6px 10px',
      borderColor: `transparent transparent transparent var(--theme)`,
      backgroundRepeat: 'no-repeat',
    },
  }),
};

export default TabNav;
